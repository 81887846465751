import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import vant
import { DatetimePicker, Picker, Popup, Toast, Tabs, Tab, Progress, Button } from 'vant'
Vue.use(DatetimePicker).use(Picker).use(Popup).use(Toast).use(Tabs).use(Tab).use(Progress).use(Button)
//视频播放
import VideoPlayer from 'vue-video-player'
Vue.use(VideoPlayer)
require('vue-video-player/src/custom-theme.css')

// 非线上添加调试工具
// import Vconsole from 'vconsole'
// if (process.env.NODE_ENV != 'production') {
//   const vConsole = new Vconsole()
//   Vue.use(vConsole)
// }

import 'amfe-flexible'
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
