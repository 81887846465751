import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const _store = {
  state: {
    accessToken: window.localStorage.getItem('accessToken') || '',
    id: parseInt(window.localStorage.getItem('id')) || '',
    subjectList: JSON.parse(window.localStorage.getItem('subjectList')) || [],
    answer: JSON.parse(window.localStorage.getItem('answer')) || [],
  },
  getters: {
    answerID(state) {
      return state.subjectList.findIndex(item => {
        return item.isAnswer == false
      })
    },
  },
  mutations: {
    setToken(state, user) {
      state.accessToken = user.token
      window.localStorage.setItem('accessToken', user.token)
    },
    setId(state, user) {
      state.id = user.id
      window.localStorage.setItem('id', user.id)
    },
    setSubject(state, subject) {
      state.subjectList = subject
      window.localStorage.setItem('subjectList', JSON.stringify(subject))
    },
    setAnswer(state, answer) {
      state.answer = answer
      window.localStorage.setItem('answer', JSON.stringify(answer))
    },
  },
  actions: {
    actanswer({ state, commit }) {
      const temparr = []
      state.subjectList.forEach(item => {
        if (item.isAnswer) {
          const oid = {
            subjectId: item.id,
            subjectOptions: [],
          }
          item.assessmentSubjectOptionList.forEach(item => {
            if (item.isSelect) {
              oid.subjectOptions.push(item.id)
            }
          })
          temparr.push(oid)
        }
      })
      commit('setAnswer', temparr)
    },
  },
  modules: {},
}
const store = new Vuex.Store({ ..._store })
export default store
